<template>
  <section
    class="relative w-screen h-full flex justify-center items-center min-h-screen content-center bg-blueGray-600"
  >
    <div class="container mx-auto h-full" style="max-width: 80%">
      <div class="flex content-center items-center justify-center h-full">
        <div class="w-full lg:w-4/12">
          <div
            class="relative mx-auto flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
          >
            <div class="rounded-t mb-0 px-6 py-6">
              <div class="text-center mb-3">
                <h6 class="text-blueGray-500 font-bold text-xl">
                  {{ $t("Send password reset link") }}
                </h6>
              </div>
            </div>
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div
                v-if="message"
                class="border border-green-600 px-3 py-2 mb-3 text-green-600 bg-blueGray-100 text-xs"
              >
                <div class="text-lg font-extrabold text-green-700">
                  {{ $t(message) }}
                </div>
              </div>
              <form>
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password"
                  >
                    {{ $t("Email") }}
                  </label>
                  <input
                    v-model="email"
                    type="email"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Email"
                  />
                </div>
                <div class="text-center mt-6">
                  <button
                    @click="handleSubmitSendPasswordResetLink"
                    class="text-white text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button"
                    :disabled="!email"
                    :class="{
                      'bg-blueGray-800 active:bg-blueGray-600': email,
                      'cursor-not-allowed bg-blueGray-600': !email
                    }"
                  >
                    <i
                      class="fas fa-circle-notch fa-spin"
                      v-if="$store.state.resetPassword.loading"
                    />
                    {{ $t("Send Reset Password Link") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PasswordReset",
  data() {
    return {
      email: "",
      message: ""
    };
  },
  methods: {
    handleSubmitSendPasswordResetLink() {
      this.message = "";
      this.$store.dispatch("resetPassword/sendPasswordResetLink", {
        payload: {
          user: { email: this.email, agent: "web" }
        },
        cb: message => (this.message = message)
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$store.state.accounts?.user?.token) {
        vm.$router.push({ name: "Home" });
      }
    });
  }
};
</script>
